/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410162247-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqUsersApiClass {
 
   constructor() {}

  /**
   * @summary Search for users and groups by their names
   * @param {string} query - Part of user or group name to search for
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public autocompleteUsersAndGroups(
    {
      query,
      offset,
      limit
    } : {
      query: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(query)) {
      throw new Error("'query' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/users/autocomplete/${encodeURIComponent(String(query))}`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.IdentityPreviewListV1>;
  }

  /**
   * @summary Create a user
   */
  public createUser(
    body: models.UserInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/users`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

  /**
   * @summary Delete a user
   * @param {string} id - ID of the user to delete
   * @param {string} [newOwnerId] - ID of the user to take over ownership of items. If not specified and the user to delete has owned items, a &#x27;400 Bad Request&#x27; will be returned.
   * @param {boolean} [transferAclAndGroupMembership=false] - True if any ACL entries and group memberships for the user being deleted should be transferred to the new owner, false if ACL entries should be deleted and group memberships shouldn&#x27;t be transferred. When migrating users to a different authentication method, this should be set to true. When actually deleting a user, it should usually be set to false. The default is &#x27;false&#x27;.
   */
  public deleteUser(
    {
      id,
      newOwnerId,
      transferAclAndGroupMembership
    } : {
      id: string,
      newOwnerId?: string,
      transferAclAndGroupMembership?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/users/${encodeURIComponent(String(id))}`,

      params: omitBy({
        ['newOwnerId']: newOwnerId,
        ['transferAclAndGroupMembership']: transferAclAndGroupMembership
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get the user that belongs to this authentication session
   * @param {boolean} [includeGroups=false] - Whether to include groups in the output
   */
  public getMe(
    {
      includeGroups
    } : {
      includeGroups?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/users/me`,

      params: omitBy({
        ['includeGroups']: includeGroups
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

  /**
   * @summary Get a user
   * @param {string} id - ID of the user to retrieve
   * @param {boolean} [includeGroups=true] - Whether to include groups in the output
   */
  public getUser(
    {
      id,
      includeGroups
    } : {
      id: string,
      includeGroups?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/users/${encodeURIComponent(String(id))}`,

      params: omitBy({
        ['includeGroups']: includeGroups
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

  /**
   * @summary Search for user from a username
   * @param {string} authDatasourceClass - Auth datasource class of the user to retrieve
   * @param {string} authDatasourceId - Auth datasource id of the user to retrieve
   * @param {string} username - Username of the user to retrieve
   * @param {boolean} [includeGroups=false] - Whether to include groups in the output
   */
  public getUserFromUsername(
    {
      authDatasourceClass,
      authDatasourceId,
      username,
      includeGroups
    } : {
      authDatasourceClass: string,
      authDatasourceId: string,
      username: string,
      includeGroups?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(authDatasourceClass)) {
      throw new Error("'authDatasourceClass' parameter required");
    }

    if (isNil(authDatasourceId)) {
      throw new Error("'authDatasourceId' parameter required");
    }

    if (isNil(username)) {
      throw new Error("'username' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/users/search`,

      params: omitBy({
        ['authDatasourceClass']: authDatasourceClass,
        ['authDatasourceId']: authDatasourceId,
        ['username']: username,
        ['includeGroups']: includeGroups
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

  /**
   * @summary Get a collection of users
   * @param {string} [usernameSearch] - Search text by which to filter users&#x27; names.
   * @param {string} [emailSearch] - Search text by which to filter users&#x27; email addresses.
   * @param {string} [firstNameSearch] - Search text by which to filter users&#x27; first names.
   * @param {string} [lastNameSearch] - Search text by which to filter users&#x27; last names.
   * @param {string} [datasourceNameSearch] - Search text by which to filter users&#x27; directories.
   * @param {boolean} [isAdmin] - Whether to filter users to only users who are administrators.
   * @param {boolean} [isEnabled] - Whether to filter users to only users who are enabled.
   * @param {boolean} [isLoggedIn] - Whether to filter users to only users who are logged in.
   * @param {boolean} [isCreated] - Whether to filter users to only users who are not system managed.
   * @param {string} [sortOrder=email asc] - A field by which to order the users followed by a space and &#x27;asc&#x27; or &#x27;desc&#x27;. Field name can be one of: username, email, firstName, lastName, directory
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [isPasswordResetRequested] - Whether to filter users to only users who are required to change their password on next login.
   * @param {string} [roleSearch] - User role
   * @param {string} [manager] - Manager ID
   * @param {string} [siteSearch] - Site
   */
  public getUsers(
    {
      usernameSearch,
      emailSearch,
      firstNameSearch,
      lastNameSearch,
      datasourceNameSearch,
      isAdmin,
      isEnabled,
      isLoggedIn,
      isCreated,
      sortOrder,
      offset,
      limit,
      isPasswordResetRequested,
      roleSearch,
      manager,
      siteSearch
    } : {
      usernameSearch?: string,
      emailSearch?: string,
      firstNameSearch?: string,
      lastNameSearch?: string,
      datasourceNameSearch?: string,
      isAdmin?: boolean,
      isEnabled?: boolean,
      isLoggedIn?: boolean,
      isCreated?: boolean,
      sortOrder?: string,
      offset?: number,
      limit?: number,
      isPasswordResetRequested?: boolean,
      roleSearch?: string,
      manager?: string,
      siteSearch?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/users`,

      params: omitBy({
        ['usernameSearch']: usernameSearch,
        ['emailSearch']: emailSearch,
        ['firstNameSearch']: firstNameSearch,
        ['lastNameSearch']: lastNameSearch,
        ['datasourceNameSearch']: datasourceNameSearch,
        ['isAdmin']: isAdmin,
        ['isEnabled']: isEnabled,
        ['isLoggedIn']: isLoggedIn,
        ['isCreated']: isCreated,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit,
        ['isPasswordResetRequested']: isPasswordResetRequested,
        ['roleSearch']: roleSearch,
        ['manager']: manager,
        ['siteSearch']: siteSearch
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputListV1>;
  }

  /**
   * @summary Update password for a user authenticating through Seeq
   * @param {string} id - ID of the user to set password for
   */
  public updatePassword(
    body: models.UserPasswordInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/users/${encodeURIComponent(String(id))}/password`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

  /**
   * @summary Update a user
   * @param {string} id - ID of the user to update
   */
  public updateUser(
    body: models.UserInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/users/${encodeURIComponent(String(id))}/update`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

}


export const sqUsersApi = new sqUsersApiClass();
