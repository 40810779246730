// @ts-strict-ignore
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { ItemPreviewWithAssetsV1 } from '@/sdk/model/ItemPreviewWithAssetsV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import moment from 'moment-timezone';
import { FrontendDuration } from '@/services/systemConfiguration.types';

export type SummaryValue = undefined | FrontendDuration | number;

export const enum INTERACTIVE_KEY {
  ALL = 'all',
  NONE = 'none',
  KEEP = 'keep',
}

export interface InteractiveReportContent {
  key: INTERACTIVE_KEY;
  makeInteractive: boolean;
  name: string;
}

export interface InteractiveReportContentStatuses {
  ALL_INTERACTIVE: InteractiveReportContent;
  NONE_INTERACTIVE: InteractiveReportContent;
}

export interface ReportContentSummary {
  key: SummaryTypeEnum;
  name: string;
  value: SummaryValue;
  variable: boolean;
}

export const KEEP_NAME = 'REPORT.CONTENT.KEEP';
export const KEEP_CURRENT_KEY = 'keep';

export interface Content {
  name: string;
  id: string;
  height: number;
  width: number;
  scale: number;
  timezone?: string;
  workbookId: string;
  worksheetId: string;
  workstepId: string;
  workbookName: string;
  worksheetName: string;
  useSizeFromRender?: boolean;
  dateRangeId?: string;
  assetSelectionId?: string;
  reportId?: string;
  isArchived?: boolean;
  summary?: ReportContentSummary;
  hashCode?: string;
  isReact?: boolean;
  screenshotWarning?: string;
  showWarningMessage?: boolean;
  hideUncertainty: boolean | null;
}

export const DOCUMENT_LAYOUT = [
  { text: 'PDF_PREVIEW.LAYOUT.PORTRAIT', value: 'Portrait' },
  { text: 'PDF_PREVIEW.LAYOUT.LANDSCAPE', value: 'Landscape' },
] as const;
// See: https://pptr.dev/api/puppeteer.paperformat
export const DOCUMENT_PAPER_SIZE = [
  {
    text: 'PDF_PREVIEW.PAPER_SIZE.LETTER',
    value: SeeqNames.PdfSettings.PageSize.Letter,
    width: '8.5in',
    height: '11in',
  },
  {
    text: 'PDF_PREVIEW.PAPER_SIZE.LEGAL',
    value: SeeqNames.PdfSettings.PageSize.Legal,
    width: '8.5in',
    height: '14in',
  },
  {
    text: 'PDF_PREVIEW.PAPER_SIZE.A3',
    value: SeeqNames.PdfSettings.PageSize.A3,
    width: '11.7in',
    height: '164.5in',
  },
  {
    text: 'PDF_PREVIEW.PAPER_SIZE.A4',
    value: SeeqNames.PdfSettings.PageSize.A4,
    width: '8.27in',
    height: '11.7in',
  },
  {
    text: 'PDF_PREVIEW.PAPER_SIZE.A5',
    value: SeeqNames.PdfSettings.PageSize.A5,
    width: '5.83in',
    height: '8.27in',
  },
  {
    text: 'PDF_PREVIEW.PAPER_SIZE.TABLOID',
    value: SeeqNames.PdfSettings.PageSize.Tabloid,
    width: '11in',
    height: '17in',
  },
] as const;
export const DOCUMENT_MARGIN_UNITS = [
  { shortLabel: SeeqNames.PdfSettings.MarginUnits.Inches, unit: ['in'] },
  { shortLabel: SeeqNames.PdfSettings.MarginUnits.Centimeters, unit: ['cm'] },
  { shortLabel: SeeqNames.PdfSettings.MarginUnits.Millimeters, unit: ['mm'] },
  { shortLabel: SeeqNames.PdfSettings.MarginUnits.Pixels, unit: ['px'] },
] as const;

export const DefaultMargin: Margin = {
  value: 0.5,
  units: DOCUMENT_MARGIN_UNITS[0].shortLabel, // in
};

export interface Margin {
  value: number;
  units: DocumentMarginUnits;
}

export interface PageMargins {
  top: Margin;
  bottom: Margin;
  left: Margin;
  right: Margin;
}

// A date in the distant future, useful for when the API expects a cron expression but
// there is no meaningful value until further user action
export const QUARTZ_CRON_PENDING_INPUT = '59 59 23 31 12 ? 2099';

export interface AssetSelection {
  name: string;
  asset: ItemPreviewWithAssetsV1;
  id: string | null;
  reportId: string;
  isArchived?: boolean;
  assetPathDepth: number | null;
}

export interface DateRangeSwapInfo {
  dateRange: DateRange;
  assetSelection: AssetSelection;
  swapItem: { id: string };
  swapAsset: any;
}

export interface ContentDisplayMetadata {
  contentId: string;
  refresh?: { silently?: boolean };
  errorClass?: string;
  error?: string;
  errorCode?: number;
}

/**
 * Start/end times for a piece of content
 */
export interface Range {
  start: number;
  end: number;
}

/**
 * Condition information for a piece of content
 */
export interface DateRangeCondition {
  id: string;
  name: string;
  strategy: string;
  reference: string;
  offset: string;
  range: Range; // search range used to find a capsule
  isRedacted: boolean;
  columns?: string[]; // saved columns when the range created using a capsule
  isCapsuleFromTable?: boolean;
  maximumDuration?: {
    units: string;
    value: number;
  };
  sortBy?: string;
  sortAsc?: boolean;
}

/**
 * DateRange live or scheduled information as represented in the frontend, parsed from the API representation
 */
export interface DateRangeAuto {
  enabled: boolean;
  duration: FrontendDuration;
  offsetDirection: string;
  offset: { value: number; units: string };
  background: boolean;
  cronSchedule?: string[];
  noCapsuleFound: boolean;
}

/**
 * Schedule for a scheduled report, including whether the report runs in the background
 */
export interface ReportSchedule {
  cronSchedule?: string[];
  background?: boolean;
  enabled: boolean;
}

/**
 * DateRange as represented in the frontend, parsed from the API representation
 */
export interface DateRange {
  id: string;
  name: string;
  description?: string;
  range: Range; // Effective range applied to the content
  enabled: boolean; // Whether this date range is enabled to run jobs
  auto: DateRangeAuto;
  condition?: DateRangeCondition;
  reportId: string;
  isArchived?: boolean;
  irregularFormula?: string;
}

export interface ReportContentSummaries {
  DISCRETE: ReportContentSummary;
  NONE: ReportContentSummary;
  AUTO: ReportContentSummary;
}

export interface ReportContentSize {
  key: string;
  name: string;
  width: number;
}

export interface ReportUpdateMessageWithSpecificUpdates {
  contentIds?: {
    updated: string[];
  };
  dateRangeIds?: {
    inserted?: string[];
    updated?: string[];
    removed?: string[];
  };
  assetSelectionIds?: {
    inserted?: string[];
    updated?: string[];
    removed?: string[];
  };
  commentIds?: {
    inserted?: string[];
    updated?: string[];
    removed?: string[];
  };
}

export type DocumentLayout = typeof DOCUMENT_LAYOUT[number];

export interface ReportContentSizes {
  SMALL: ReportContentSize;
  MEDIUM: ReportContentSize;
  LARGE: ReportContentSize;
  CUSTOM: ReportContentSize;
}

export interface ReportContentShape {
  key: string;
  name: string;
  width: number;
  height: number;
}

export interface ReportContentShapes {
  STRIP: ReportContentShape;
  RECTANGLE: ReportContentShape;
  SQUARE: ReportContentShape;
}

export interface ReportContentUncertaintyValue {
  key: string;
  name: string;
  value?: string;
}

export interface ReportContentUncertaintyValues {
  SHOW: ReportContentUncertaintyValue;
  HIDE: ReportContentUncertaintyValue;
}

export interface ReportContentScale {
  key: number;
  name: string;
}

export interface ReportContentScales {
  SMALL: ReportContentScale;
  NORMAL: ReportContentScale;
  LARGE: ReportContentScale;
  XLARGE: ReportContentScale;
  HUGE: ReportContentScale;
}

export interface SandboxMode {
  enabled: boolean;
  originalWorksheetId: string | null;
  sandboxedWorksheetId: string | null;
  sandboxedWorkbookId: string | null;
  sandboxOriginalCreatorName?: string | null;
}

export enum ReportEditingState {
  Saved = 'Saved',
  Saving = 'Saving',
  Offline = 'Offline',
  Stale = 'Stale',
}

export enum ReportEditingStateEvent {
  SaveComplete = 'SaveComplete',
  SaveStarted = 'SaveStarted',
  Offline = 'Offline',
  Online = 'Online',
}

export enum ASSET_SELECTION_WARNING_STATUS {
  DISMISSED,
  ICONS,
  MESSAGES,
  MIXED,
}

export enum ReportClickActions {
  None,
  Warning,
  Refresh,
  Edit,
  ChangeName,
  /** Ranges only **/
  Step,
  /** Asset Selections Only **/
  ChangeSibling,
}

export enum ReportUpdateMessageType {
  FULL_UPDATE,
  SPECIFIC_UPDATES_ONLY,
}

export type ReportUpdateMessage = {
  type: ReportUpdateMessageType;
  message?: ReportUpdateMessageWithSpecificUpdates;
};
export type LiveScreenshotMessage =
  | LiveScreenshotMessageContent
  | LiveScreenshotMessageDateRange
  | LiveScreenshotMessageError;

// Matches LiveScreenshotMessage.kt and ItemErrorMessage.java
export interface ScreenshotMessageBase {
  channelId: string;

  /// HTTP status code
  status: number;

  /// HTTP status reason (e.g. "OK" or "Not Found")
  statusText?: string;
}

export type LiveScreenshotMessageContent = ScreenshotMessageBase & {
  status: 200;
  content: {
    contentId: string;
    url: string;
    hashCode: string;
    warning?: string;
  };
};
export const isContentMessage = (msg: LiveScreenshotMessage): msg is LiveScreenshotMessageContent =>
  msg.status === 200 && (msg as LiveScreenshotMessageContent).content != null;
export const isReportForbiddenMessage = (msg: LiveScreenshotMessageError): msg is LiveScreenshotMessageError =>
  msg.status === 403 && (msg as LiveScreenshotMessageError).itemType === SeeqNames.Types.Report;
export type LiveScreenshotMessageDateRange = ScreenshotMessageBase & {
  status: 200;
  dateRange: {
    dateRangeId: string;
    start: string;
    end: string;
  };
};
export const isDateRangeMessage = (msg: LiveScreenshotMessage): msg is LiveScreenshotMessageDateRange =>
  msg.status === 200 && (msg as LiveScreenshotMessageDateRange).dateRange != null;
export type LiveScreenshotMessageError = ScreenshotMessageBase & {
  statusMessage?: string;
  itemType?: string;
  itemId?: string;
};
export const isErrorMessage = (msg: LiveScreenshotMessage): msg is LiveScreenshotMessageError => msg.status !== 200;

export enum CONTENT_LOADING_CLASS {
  ERROR = 'contentLoadError',
  NO_CAPSULE_ERROR = 'contentNoCapsuleError',
  SPINNER = 'loadingSpinner',
  SPINNER_CORNER = 'loadingSpinnerCorner',
  LOADED = 'contentLoaded',
  NOT_RENDERED = 'notRenderedWrapper',
  REACT_WRAPPER = 'reactWrapper',
}

export enum CONTENT_STATE {
  ANY = '*',
  NONE = 'none',
  WORKBOOK = 'workbook',
  LINK = 'link',
  WORKSHEET = 'worksheet',
  PROPERTIES = 'properties',
  INSERT = 'insert',
  DONE = 'done',
  LOAD_PROPERTIES = 'load properties',
}

export type DocumentPaperSize = typeof DOCUMENT_PAPER_SIZE[number];
export type DocumentMarginUnits = typeof DOCUMENT_MARGIN_UNITS[number]['shortLabel'];

export enum OFFSET_DIRECTION {
  PAST = 'past',
  FUTURE = 'future',
}

export type CapsuleSelectionValue = typeof CAPSULE_SELECTION_OPTIONS[number]['value'];
export const REPORT_CONTENT = {
  INTERACTIVE: {
    ALL_INTERACTIVE: {
      key: INTERACTIVE_KEY.ALL,
      makeInteractive: true,
      name: 'REPORT.INTERACTIVE.ALL',
    },
    NONE_INTERACTIVE: {
      key: INTERACTIVE_KEY.NONE,
      makeInteractive: false,
      name: 'REPORT.INTERACTIVE.NONE',
    },
  } as InteractiveReportContentStatuses,
  SIZE: {
    SMALL: { key: 'small', name: 'REPORT.SIZE.SMALL', width: 350 },
    MEDIUM: { key: 'medium', name: 'REPORT.SIZE.MEDIUM', width: 700 },
    LARGE: { key: 'large', name: 'REPORT.SIZE.LARGE', width: 1050 },
    CUSTOM: { key: 'custom', name: 'REPORT.SIZE.CUSTOM', width: undefined },
  } as ReportContentSizes,
  SHAPE: {
    STRIP: { key: 'strip', name: 'REPORT.SHAPE.STRIP', width: 16, height: 4 },
    RECTANGLE: {
      key: 'rectangle',
      name: 'REPORT.SHAPE.RECTANGLE',
      width: 16,
      height: 9,
    },
    SQUARE: {
      key: 'square',
      name: 'REPORT.SHAPE.SQUARE',
      width: 15,
      height: 15,
    },
  } as ReportContentShapes,
  SCALE: {
    SMALL: { key: 0.7, name: 'REPORT.SCALE.SMALL' },
    NORMAL: { key: 1.0, name: 'REPORT.SCALE.NORMAL' },
    LARGE: { key: 1.3, name: 'REPORT.SCALE.LARGE' },
    XLARGE: { key: 2.0, name: 'REPORT.SCALE.XLARGE' },
    HUGE: { key: 3.0, name: 'REPORT.SCALE.HUGE' },
  } as ReportContentScales,
  SUMMARY: {
    DISCRETE: {
      key: SummaryTypeEnum.DISCRETE,
      name: 'REPORT.SUMMARY.DISCRETE',
      value: { value: 0, units: 'min' },
      variable: true,
    },
    NONE: {
      key: SummaryTypeEnum.NONE,
      name: 'REPORT.SUMMARY.NONE',
      variable: false,
    },
    AUTO: {
      key: SummaryTypeEnum.AUTO,
      name: 'TOOLBAR.SUMMARY.OPTIONS.AUTO',
      value: 0,
      variable: true,
    },
  } as ReportContentSummaries,
  CERTAINTY: {
    SHOW: {
      key: 'show',
      name: 'REPORT.CERTAINTY.SHOW',
      value: 'show',
    },
    HIDE: {
      key: 'hide',
      name: 'REPORT.CERTAINTY.HIDE',
      value: 'hide',
    },
  } as ReportContentUncertaintyValues,
  MIN_NON_CUSTOM_HEIGHT: 130,
  CONTENT_MIN_SIZE: 1,
  CONTENT_MAX_SIZE: 10000,
};

export const KEEP_CURRENT = { key: KEEP_CURRENT_KEY, name: KEEP_NAME, value: KEEP_CURRENT_KEY } as const;

export const KEEP_CURRENT_SUMMARY = { key: KEEP_CURRENT_KEY, name: KEEP_NAME };

export const KEEP_CURRENT_INTERACTIVE = {
  key: KEEP_CURRENT_KEY,
  name: KEEP_NAME,
  makeInteractive: undefined,
} as InteractiveReportContent;
export const KEEP_CURRENT_SIZE = {
  key: KEEP_CURRENT_KEY,
  name: KEEP_NAME,
  width: undefined,
} as ReportContentSize;
export const KEEP_CURRENT_SHAPE = {
  key: KEEP_CURRENT_KEY,
  name: KEEP_NAME,
  width: undefined,
  height: undefined,
} as ReportContentShape;
export const KEEP_CURRENT_SCALE = {
  key: KEEP_CURRENT_KEY,
  name: KEEP_NAME,
} as any;
export const KEEP_CURRENT_DATE_RANGE = {
  name: KEEP_NAME,
  id: KEEP_CURRENT_KEY,
};
export const KEEP_CURRENT_ASSET_SELECTION = {
  name: KEEP_NAME,
  id: KEEP_CURRENT_KEY,
};
export const TEMP_TOPIC_NAME = 'SandBoxed Topic';
export const SCREENSHOT_SIZE_TO_CONTENT = {
  DEFAULT_WIDTH: 100,
  DEFAULT_HEIGHT: 100,
  SELECTOR: '.screenshotSizeToContent',
};
export const CAPSULE_SELECTION = {
  STRATEGY: {
    CLOSEST_TO: 'closestTo',
    OFFSET_BY: 'offsetBy',
  },
  REFERENCE: {
    START: 'start',
    END: 'end',
  },
};
export const CAPSULE_SELECTION_OPTIONS = [
  {
    value: {
      strategy: CAPSULE_SELECTION.STRATEGY.CLOSEST_TO,
      reference: CAPSULE_SELECTION.REFERENCE.START,
    },
    text: 'REPORT.CONFIG.CLOSEST_TO_START',
  },
  {
    value: {
      strategy: CAPSULE_SELECTION.STRATEGY.CLOSEST_TO,
      reference: CAPSULE_SELECTION.REFERENCE.END,
    },
    text: 'REPORT.CONFIG.CLOSEST_TO_END',
  },
  {
    value: {
      strategy: CAPSULE_SELECTION.STRATEGY.OFFSET_BY,
      reference: CAPSULE_SELECTION.REFERENCE.START,
    },
    text: 'REPORT.CONFIG.OFFSET_FROM_START',
  },
  {
    value: {
      strategy: CAPSULE_SELECTION.STRATEGY.OFFSET_BY,
      reference: CAPSULE_SELECTION.REFERENCE.END,
    },
    text: 'REPORT.CONFIG.OFFSET_FROM_END',
  },
] as const;
export const DEFAULT_DATE_RANGE = {
  auto: {
    enabled: false,
    duration: moment.duration(1, 'days').valueOf(),
    offset: { value: 0, units: 'min' },
    offsetDirection: OFFSET_DIRECTION.PAST,
    cronSchedule: [QUARTZ_CRON_PENDING_INPUT],
    noCapsuleFound: false,
  },
  condition: {
    strategy: CAPSULE_SELECTION.STRATEGY.CLOSEST_TO,
    reference: CAPSULE_SELECTION.REFERENCE.END,
    offset: 1,
    range: {},
  },
  range: {},
  enabled: true,
};
// This number chosen by experimenting with what the frontend can handle before it feels too slow. It allows
// customers to have large trees for asset selections (CRAB-34316)
export const MAX_ASSETS_FOR_SELECTION = 10000;
export const IMAGE_BORDER_CLASS = 'report-image-border';
export const DEFAULT_SIZE_KEY = REPORT_CONTENT.SIZE.MEDIUM;
export const DEFAULT_SHAPE_KEY = REPORT_CONTENT.SHAPE.RECTANGLE;
export const DEFAULT_HEIGHT = Math.round((DEFAULT_SIZE_KEY.width * DEFAULT_SHAPE_KEY.height) / DEFAULT_SHAPE_KEY.width);
export const DEFAULT_WIDTH = DEFAULT_SIZE_KEY.width;

export interface InProgressContentProperties {
  id: string;
  sourceUrl: string;
  autoUpdate: boolean;
  modalName: CONTENT_STATE | '';
  paramsModified: boolean;
  isReact: boolean;
  hideUncertainty: boolean | null;
  canUseReact: boolean;
  useSizeFromRender: boolean;
  assetSelectionId: string;
  dateRangeId: string;
  shapeConstant: any;
  shapeKey: string;
  sizeConstant: any;
  sizeKey: string;
  summary: ReportContentSummary;
  scale: string;
  height: number;
  width: number;
  worksheetName: string;
  worksheetId: string;
  workbookName: string;
  workbookId: string;
  workstepId: string;
  isNew: boolean;
  name: string;
}

// Needs to be kept up to date with worksteps.constant.ts::WORKSTEP_SCHEMA_VERSION
// This is kept separate from WORKSTEP_SCHEMA_VERSION to help ensure that devs think about interactive content
// upgrades when writing workstep upgrades.
export const INTERACTIVE_CONTENT_WORKSTEP_VERSION = 63;
